import React, { Component } from "react";
import "antd/dist/antd.css";
import {
  Input,
  Button,
  Form,
  PageHeader,
  Tag,
  DatePicker,
  Select,
  Card,
  Empty,
  Upload,
  Badge,
} from "antd";

import { PlusCircleFilled } from "@ant-design/icons";

import { getSupport, createTicket } from "../../action/support";
import history from "../../history";
import { connect } from "react-redux";
import Modal from "antd/lib/modal/Modal";
import axios from "axios";
import { Container } from "../../styles/globalStyle";

import { notificationCount } from "../../action/support";
class SupportList extends Component {
  state = {
    searchText: "",
    searchedColumn: "",
    data: [],
    serviceDetails: null,
    detail: false,
    visible: false,
    passVisible: false,
    modalVisible: false,
    editModalVisible: false,
    placement: "bottom",
    isModalVisible: false,
    current: 1,
  };
  componentDidMount = async () => {
    this.props.notificationCount();
    window.scrollTo(0, 0);
    const res = await this.props.getSupport();
    this.setState({ data: res });
  };
  createData = (
    sl,
    ticket_id,
    title,
    status,
    created_at,
    admin_seen,
    action
  ) => {
    return { sl, ticket_id, title, status, created_at, admin_seen, action };
  };

  handleRefresh = async () => {
    const res = await this.props.getUsers();
    this.setState({ data: res });
  };

  handlePassVisible = (id) => {
    this.setState({ passVisible: true, id: id });
  };
  handleModalVisible = () => {
    this.setState({ modalVisible: !this.state.modalVisible });
  };
  handleView = async (ticket) => {
    history.push(`/ticket-details?ticket=${ticket}`);
  };
  handleEditModalVisible = (user_id) => {
    this.setState({
      editModalVisible: !this.state.editModalVisible,
      id: user_id,
    });
  };
  onClosePass = () => {
    this.setState({
      passVisible: false,
    });
  };
  onClose = () => {
    this.setState({
      visible: false,
    });
  };
  makeLocalTime = (date) => {
    let mainDate = date.split("T")[0];
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      mainDate + " , " + hours + ":" + minutes + " " + (am ? "a.m" : "p.m")
    );
  };

  onFinish = async (values) => {
    const res = await this.props.createTicket(values);
    if (res === 201) {
      const response = await this.props.getSupport();
      this.setState({ data: response, isModalVisible: false });
    }
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  title = (item) => {
    return (
      <div>
        <span style={{ fontSize: "15px" }}>{item?.title}</span> &nbsp;
        <span style={{ fontSize: "10px" }}>
          <Tag style={{ fontSize: "10px" }} color="cyan">
            {item?.ticket_id}
          </Tag>
        </span>
        <span style={{ fontSize: "10px" }}>
          {item?.status === "Active" ? (
            <Tag style={{ fontSize: "10px" }} color="green">
              Active
            </Tag>
          ) : (
            <Tag style={{ fontSize: "10px" }} color="magenta">
              Closed
            </Tag>
          )}
        </span>
        <span style={{ fontSize: "10px" }}>
          <Tag style={{ fontSize: "10px" }} color="cyan">
            {item?.created_at ? this.makeLocalTime(item.created_at) : null}
          </Tag>
        </span>
        <span style={{ fontSize: "10px" }}>
          {/* {item?.merchant_seen ?
                <Tag style={{fontSize: '10px'}} color="green">Seen</Tag> : 
                <Tag style={{fontSize: '10px'}} color="red">Unseen</Tag>
                } */}
        </span>
      </div>
    );
  };

  rest = {
    name: "file",
    action: "https://api.amvines.hidayahsmart.solutions/merchant/file/upload",
    headers: {
      authorization: `Bearer ${localStorage.getItem("token")}`,
    },

    onRemove: (file) => {
      const res = axios(
        "https://api.amvines.hidayahsmart.solutions/merchant/file/delete",
        {
          headers: {
            "content-type": "application/json",
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          data: {
            file: file?.response?.name,
          },
          method: "delete",
        }
      );
    },
  };

  pageHeader = () => (
    <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Support"
      subTitle="My Tickets"
      extra={[
        <Button
          key="1"
          type="primary"
          icon={<PlusCircleFilled />}
          onClick={() => this.setState({ isModalVisible: true })}
        >
          Create New
        </Button>,
      ]}
    ></PageHeader>
  );
  dataSection = () => (
    <Card title="My tickets">
      {this.state.data ? (
        this.state.data.map((item, index) =>
          item?.merchant_seen ? (
            <>
              <Badge.Ribbon text="Seen" color="green">
                <Card
                  title={this.title(item)}
                  size="small"
                  style={{ backgroundColor: "#e2dfdf36", borderRadius: "5px" }}
                >
                  <p style={{ padding: "5px" }}>{item?.last_message}</p>
                  <Button
                    type="primary"
                    onClick={() => this.handleView(item?.ticket_id)}
                  >
                    &nbsp;&nbsp;
                    {item?.status === "Active" ? "Reply" : "Details"}
                  </Button>
                </Card>
              </Badge.Ribbon>
              {/* <Card 
              key={index}
              type="inner" 
              title={this.title(item)}
              extra={<Button icon={<EyeOutlined />} type="primary" onClick = {()=>this.handleView(item?.ticket_id)}>View</Button>}
            >
              <span style={{backgroundColor: '#fff',padding: '5px'}}>{item?.last_message}</span>
            </Card> */}
              <br />
            </>
          ) : (
            <>
              <Badge.Ribbon text="New" color="red">
                <Card
                  title={this.title(item)}
                  size="small"
                  style={{ backgroundColor: "#e0faf2", borderRadius: "5px" }}
                >
                  <p style={{ padding: "5px" }}>{item?.last_message}</p>
                  <Button
                    type="primary"
                    onClick={() => this.handleView(item?.ticket_id)}
                  >
                    &nbsp;&nbsp;
                    {item?.status === "Active" ? "Reply" : "Details"}
                  </Button>
                </Card>
              </Badge.Ribbon>
              {/* <Card 
              key={index}
              type="inner" 
              color="red"
              title={this.title(item)}
              extra={<Button icon={<EyeOutlined />} type="primary" onClick = {()=>this.handleView(item?.ticket_id)}>View</Button>}
              style={{backgroundColor: '#ff7675'}}
            >
              <span style={{backgroundColor: '#fff',padding: '5px'}}>{item?.last_message}</span>
            </Card> */}
              <br />
            </>
          )
        )
      ) : (
        <Empty />
      )}
    </Card>
  );
  modals = () => (
    <Modal
      title="Create ticket"
      onOk={() => this.setState({ isModalVisible: false })}
      visible={this.state.isModalVisible}
      onCancel={() => this.setState({ isModalVisible: false })}
    >
      <Form
        name="basic"
        labelCol={{
          span: 4,
        }}
        wrapperCol={{
          span: 18,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
      >
        <Form.Item
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: "Required..!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Message"
          name="message"
          rules={[
            {
              required: true,
              message: "Required!",
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>

        <Form.Item name="file" style={{ alignSelf: "flex-end" }}>
          <Upload {...this.rest} listType="picture" maxCount={1}>
            <Button>Upload file</Button>
          </Upload>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
  render() {
    return (
      <Container>
        {this.pageHeader()}
        {this.dataSection()}
        {this.modals()}
      </Container>
    );
  }
}

export default connect(null, { getSupport, createTicket, notificationCount })(
  SupportList
);
