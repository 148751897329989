import React, { Component } from "react";
import {
  Table,
  Tag,
  Space,
  DatePicker,
  Modal,
  Button,
  Input,
  Row,
  Col,
  Select,
  Tooltip,
  Popconfirm,
  Pagination,
  Skeleton,
  Form,
  PageHeader,
} from "antd";

import {
  CameraOutlined,
  CheckCircleOutlined,
  ClearOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeFilled,
  PlusSquareOutlined,
  StopOutlined,
} from "@ant-design/icons";
// import AddProduct from './addProduct';
import { connect } from "react-redux";
import { fetchOrders, cancelOrder } from "../../action/parcel";
import { fetchShops,fetchStore } from "../../action/shop";
import Highlighter from "react-highlight-words";
import { SearchOutlined } from "@ant-design/icons";
// import EditProduct from './editProduct';
// import UpdateImage from './updateImage';
import history from "../../history";
import { Container, FlexContainer } from "../../styles/globalStyle";
import {notificationCount} from "../../action/support";
import CreateStore from "../store/createStore";
import AddPaymentMethod from "../store/addPaymentMethod";
import './style.css'
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import QRCode from 'qrcode.react';
// import bahon from './stickerLogo.png';
import ReactToPrint from "react-to-print";
import styled from "styled-components";
const { RangePicker } = DatePicker;
const { Option } = Select;
let pdata = []
const InvoiceContainer = styled.div`
    /* margin: 15px; */
    
    width: 210mm;
    height: 297mm;
    color: #000;
    p{
        font-size: 14px;
        font-weight: bold;
        color: #000;
        padding: 0px ;
        margin: 0px ;
    }
    span{
        font-size: 11px;
    }
    img{
        width: 110px;
    }
    h5{
        border-bottom: 1px dotted black;
        margin: 0px ;
    }
    button{
      padding: 2px 6px;
      border: none ;
    }
    
`;


class PrintBarCode extends Component {
  state ={
    invoice : pdata,
    flag : false,
  }
  printDocument() {
    const input = document.getElementById('divToPrint');
    html2canvas(input)
      .then((canvas) => {
        let imgWidth = 208;
        let imgHeight = canvas.height * imgWidth / canvas.width;
        const imgData = canvas.toDataURL('img/png');
        const pdf = new jsPDF('p', 'mm', 'a4');
        pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
        // pdf.output('dataurlnewwindow');
        pdf.save(pdata?.order_date.split("T")[0] + "---" + pdata?.merchant_order_id)  ;
      })
    ;
  }

  componentDidMount = () => {
    // console.log(pdata?.bahon_id)
    this.printDocument()
    this.props.clsfnc()
    
  }
  render() {
    var today = new Date();
    const date = pdata.order_date.split("T")[0];
    const t = pdata.order_date.split("T")[1];
    const time = t.split(".")[0];
    const hour = (today.getHours() + 24) % 12 || 12
    const orderDate = new Date(pdata.order_date);
    return(
     <>
        <Button type="primary" onClick={this.printDocument} >Download</Button>
        <InvoiceContainer id="divToPrint" style={{padding: '1px'}}>
          <div style={{margin: '20px'}}>
          <Row gutter={2} style={{margin: '0px',padding: '0px'}} >
            
            <Col  xs={16} style={{borderBottom: '1px solid #000',paddingBottom: '20px'}}>
              <p style={{fontSize: '14px'}} >{pdata.shop_name}</p>
            </Col>
            <Col  xs={8} style={{borderBottom: '1px solid #000',paddingBottom: '20px'}}>
              <button>Invoice No: {pdata.merchant_order_id}</button>
              <p style={{fontSize: '10px', fontWeight: 'normal'}} >{date + ' ' + time}</p>
            </Col>
           
          </Row>
            <br />
            <br />
            {/* ===========Info== */}
           
            <Row gutter={0} style={{margin: '0px'}}>
              <Col  xs={24}><h5 style={{fontWeight: 'bold'}}>Customer Details</h5></Col>
              <Col  xs={6} style={{padding: '0px'}}><h4>Name</h4></Col>
              <Col  xs={12} style={{padding: '0px'}}><h4>Address</h4></Col>
              <Col  xs={6} style={{padding: '0px'}}><h4>Phone</h4></Col>
              
              <Col  xs={6}><p style={{fontWeight:'bold'}}> {pdata.customer_name}</p></Col>
              <Col  xs={12}><p style={{fontWeight:'bold'}}> {pdata.customer_address}</p></Col>
              <Col  xs={6}><p style={{fontWeight:'bold'}}> {pdata.customer_phone}</p></Col>
            </Row>
            
            <br />
            <br />

            <Row gutter={0} style={{margin: '0px'}}>
                
                <Col  xs={24}><h5 style={{fontWeight: 'bold'}}>Product Details</h5></Col>
                  <Col  xs={6} style={{padding: '0px'}}><h4>Quantity</h4></Col>
                  <Col  xs={12} style={{padding: '0px'}}><h4>Description</h4></Col>
                  <Col  xs={6} style={{padding: '0px'}}><h4>Amount</h4></Col>

                  <Col  xs={6}><p> {pdata?.item}</p></Col>
                  <Col  xs={12}><p> {pdata?.product_description}</p></Col>
                  <Col xs={6}> <p> {pdata?.amount_to_collect} 	&#2547;</p></Col>
            </Row>
            
            <br />
            <br />
            <Row container spacing={1} style={{ fontSize:'10px', fontWeight:'bold', margin: '0px'}}>
              <Col xs={12}>
                <QRCode value={pdata.bahon_id} size={55}/>
              </Col>
              <Col item xs={12} style={{textAlign:'right'}}>{today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds()} </Col>
            </Row>
          </div>
        </InvoiceContainer>
        

  </>
    )
  }
}


class ParcelList extends React.Component {
  state = {
    product_id: null,
    isEditModalVisible: false,
    isImageModalVisible: false,
    searchText: "",
    searchedColumn: "",
    data: null,
    name: "",
    product_id: "",
    active: "",
    page: 1,
    total: null,
    shops: null,

    shop_id: "",
    status: "",
    receipent_mobile: "",
    startDate: null,
    endDate: null,
    merchant_id: "",
    bahon_id: "",
    lan: "english",
    createForm: false,
    isValid: null,
    createPaymentForm: false,
    visible: false,
  };

  componentDidMount = async () => {
    
    // =========== Get notification count  =========
    this.props.notificationCount()
   
    window.scrollTo(0, 0);
    this.setState({
      lan: localStorage.getItem("lan")
        ? localStorage.getItem("lan")
        : "english",
    });

    // =========== Get all shops  =========
    const res = await this.props.fetchShops();
    this.setState({ shops: res });
    let id= localStorage.getItem('active_store') ? localStorage.getItem('active_store') : res[0].shop_id
    this.setState({shop_id: id})
    if(localStorage.getItem('active_store')){
      let id= localStorage.getItem('active_store') 
      this.setState({shop_id: id})

      // =========== Get individual shop  =========
      const result = await this.props.fetchStore(id)
      if(result?.payment_info == null){
        this.setState({isValid : false})
        this.setState({ data: null, total: null });
      }else{
        this.setState({isValid : true})
        
        const {
          status,
          page,
          receipent_mobile,
          startDate,
          endDate,
          merchant_id,
          bahon_id,
        } = this.state;
        
        // =========== Get all orders  =========
        const res = await this.props.fetchOrders(
          id,
          status,
          page,
          receipent_mobile,
          startDate,
          endDate,
          merchant_id,
          bahon_id
        );
        this.setState({ data: res?.orders, total: res.count });
      }
    }
    else{
      
      localStorage.setItem('active_store',res[0].shop_id)
      localStorage.setItem('active_store_name',res[0].name)
      let id = res[0].shop_id
      // =========== Get individual store =========
      const result = await this.props.fetchStore(id)
      if(result?.payment_info == null){
        this.setState({isValid : false})
        this.setState({ data: null, total: null });
      }else{
        this.setState({isValid : true})
        
        const {
          status,
          page,
          receipent_mobile,
          startDate,
          endDate,
          merchant_id,
          bahon_id,
        } = this.state;
        
        // =========== Get all store =========
        const res = await this.props.fetchOrders(
          id,
          status,
          page,
          receipent_mobile,
          startDate,
          endDate,
          merchant_id,
          bahon_id
        );
        this.setState({ data: res?.orders, total: res.count });
      }
      
    }
  };

  onCreate = () => {
    this.setState({ createForm: true });
  };

  onClose = () => {
    this.setState({ createForm: false });
  };
  onPaymentClose = () => {
    this.setState({ createPaymentForm: false });
  };

  onFinish = async (values) => {
    // const {shop_Id,status,page,receipent_mobile,startDate, endDate, merchant_id,bahon_id} = this.state
    // const res = await this.props.fetchOrders(shop_Id,status,page,receipent_mobile,startDate,endDate, merchant_id,bahon_id)
    // this.setState({data: res.product,total:res.count})
  };

  handleShopSearch = async (e) => {
    
    window.scrollTo(0, 0);
    localStorage.setItem('active_store', e)
    this.setState({ shop_id: e });
    const {
      status,
      page,
      receipent_mobile,
      startDate,
      endDate,
      merchant_id,
      bahon_id,
    } = this.state;

    const result = await this.props.fetchStore(e)
    
    localStorage.setItem('active_store_name', result?.name)
    if(result?.payment_info == null){
      this.setState({isValid : false})
      this.setState({ data: null, total: null });
    }else{
      this.setState({isValid : true})
      const res = await this.props.fetchOrders(
        e,
        status,
        page,
        receipent_mobile,
        startDate,
        endDate,
        merchant_id,
        bahon_id
      );
      this.setState({ data: res?.orders, total: res.count });
    }
   
  }; 
  handleShopRefresh = async () => {
    

    window.scrollTo(0, 0);
    const {
      status,
      page,
      receipent_mobile,
      startDate,
      endDate,
      merchant_id,
      bahon_id,
      shop_id,
    } = this.state;

    const result = await this.props.fetchStore(shop_id)
    if(result?.payment_info == null){
      this.setState({isValid : false})
      this.setState({ data: null, total: null });
    }else{
      this.setState({isValid : true})
      const res = await this.props.fetchOrders(
        shop_id,
        status,
        page,
        receipent_mobile,
        startDate,
        endDate,
        merchant_id,
        bahon_id
      );
      this.setState({ data: res?.orders, total: res.count });
    }
   
  };
  onFinishFailed = (errorInfo) => {};
  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            this.handleSearch(selectedKeys, confirm, dataIndex)
          }
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  handlePrintData = (data) => {
    pdata = []
    let invoice = {
      'shop_name' : localStorage.getItem('active_store_name'),
      'shop_mobile' : localStorage.getItem('mobile_number'),
      'merchant_order_id' : data.merchant_order_id,
      'customer_name' : data.recipient_name,
      'customer_phone' : data.recipient_mobile_number,
      'customer_address' : data.recipient_address,
      'product_description' : data?.item_description,
      'weight_range' : data?.weight,
      'amount_to_collect' : data?.charges?.amount_to_collect,
      'order_date' : data?.created_at,
      'bahon_id' : data?.order_id,
      'item': data?.quantity,
  }
  pdata=invoice
  // localStorage.setItem('invoice',JSON.stringify(invoice))
  this.setState({visible: true})
  }
  createData = (
    key,
    order_id,
    recipient,
    charges,
    weight,
    quantity,
    type,
    status,
    details,
    action
  ) => {
    return {
      key,
      order_id,
      recipient,
      charges,
      weight,
      quantity,
      type,
      status,
      details,
      action,
    };
  };
  makeLocalTime = (date) => {
    let mainDate = date.split("T")[0];
    let hours = date.split("T")[1].split(":")[0];
    let minutes = date.split("T")[1].split(":")[1];
    var am = true;
    if (hours > 12) {
      am = false;
      hours -= 12;
    } else if (hours == 12) {
      am = false;
    } else if (hours == 0) {
      hours = 12;
    }
    return (
      mainDate + " , " + hours + ":" + minutes + " " + (am ? "a.m" : "p.m")
    );
  };
  handleApiSearch = async () => {
    window.scrollTo(0, 0);
    const {
      shop_id,
      status,
      page,
      receipent_mobile,
      startDate,
      endDate,
      merchant_id,
      bahon_id,
    } = this.state;

    const res = await this.props.fetchOrders(
      shop_id,
      status,
      page,
      receipent_mobile,
      startDate,
      endDate,
      merchant_id,
      bahon_id
    );
    this.setState({ data: res.orders, total: res.count });
  };
  resetSearch = async () => {
    const res = await this.props.fetchOrders(
      this.state.shop_id,
      "",
      1,
      "",
      null,
      null,
      "",
      ""
    );
    this.setState({ data: res.orders, total: res.count });
  };
  handleCancel = async (order) => {
    const res = await this.props.cancelOrder(this.state?.shop_id, order);
    if (res === 201) {
      this.handleRefresh();
    }
  };
  handlePagination = async (page) => {
    window.scrollTo(0, 0);
    if (page) {
      this.setState({ page: page });
    }
    const {
      shop_id,
      status,
      receipent_mobile,
      startDate,
      endDate,
      merchant_id,
      bahon_id,
    } = this.state;
    const res = await this.props.fetchOrders(
      shop_id,
      status,
      page,
      receipent_mobile,
      startDate,
      endDate,
      merchant_id,
      bahon_id
    );
    this.setState({ data: res.orders, total: res.count });
  };
  handleRefresh = async () => {
    const {
      shop_id,
      status,
      page,
      receipent_mobile,
      startDate,
      endDate,
      merchant_id,
      bahon_id,
    } = this.state;
    const res = await this.props.fetchOrders(
      shop_id,
      status,
      page,
      receipent_mobile,
      startDate,
      endDate,
      merchant_id,
      bahon_id
    );
    this.setState({ data: res.orders, total: res.count });
  };
  createOptions = (label, value) => {
    return { label, value };
  };
  handleDate = (date) => {
    if (date) {
      const ds = new Date(date[0]._d);
      const de = new Date(date[1]._d);
      this.setState({ startDate: ds.toISOString(), endDate: de.toISOString() });
    } else {
      this.setState({ startDate: null, endDate: null });
      return;
    }
  };

  render() {
    const { shops, lan } = this.state;
    let option = [];
    option = shops
      ? shops.map((item) => this.createOptions(item.name, item.shop_id))
      : null;
    const columns = [
      {
        title: "Merchant ID",
        dataIndex: "key",
        key: "key",
        width: "15%",
        ...this.getColumnSearchProps("key"),
      },
      {
        title: "Bahon ID",
        dataIndex: "order_id",
        key: "order_id",
        width: "10%",
        ...this.getColumnSearchProps("order_id"),
      },
      {
        title: "Recipient",
        dataIndex: "recipient",
        key: "recipient",
        width: "15%",
      },
      {
        title: "Charges",
        dataIndex: "charges",
        key: "charges",
        width: "15%",
      },
      {
        title: "Weight (kg)",
        dataIndex: "weight",
        key: "weight",
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
      },
      {
        title: "Order Type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        width: "10%",
      },
      {
        title: "Action",
        key: "action",
        dataIndex: "action",
      },
    ];

    const { data, total } = this.state;

    const rows = [
      data
        ? data.map((data) =>
            this.createData(
              <>
                <p>{data?.merchant_order_id}</p>
                <p>{this.makeLocalTime(data.created_at)}</p>
              </>,
              data?.order_id,
              <>
                <p style={{margin: '0px',cursor: 'pointer', fontWeight: 'bold'}}>{data?.recipient_name}</p>
                {/* <p>{data?.recipient_mobile_number}</p>
                <p>{data?.recipient_address}</p> */}
              </>,
              
                data.type === "NO" ? 
                  <p style={{margin: '0px',cursor: 'pointer', fontWeight: 'bold'}}>Payable Amount: {data?.charges?.payable_amount} tk</p>
                : 
                <p style={{margin: '0px',cursor: 'pointer', fontWeight: 'bold'}}>Return Charge: {data?.charges?.return_charge} tk</p>
                
               ,
              data?.weight,
              data?.quantity,
              <>
                {data.type === "NO" ? (
                  <>
                    <Tag >Regular Parcel</Tag>
                  </>
                ) : (
                  <>
                    <Tag color="#df2f2f">Return Parcel</Tag>
                  </>
                )}
              </>,
              <>
                {data.state === "Pending" ? (
                  <Tag color="gold">Pending</Tag>
                ) : data.state === "In-Transit" ? (
                  <Tag color="gold">In-Transit</Tag>
                ) : data.state === "Confirmed" ? (
                  <Tag color="cyan">Confirmed</Tag>
                ) : data.state === "Picked" ? (
                  <Tag color="cyan">Picked</Tag>
                ) : data.state === "On Hold (Driver)" ? (
                  <Tag color="orange">On Hold (Driver)</Tag>
                ) : data.state === "On Hold (Warehouse)" ? (
                  <Tag color="orange">On Hold (Warehouse)</Tag>
                ) : data.state === "Delivered" ? (
                  <Tag color="green">Delivered</Tag>
                ) : data.state === "Partially Delivered" ? (
                  <Tag color="green">Partially Delivered</Tag>
                ) : data.state === "Out-For-Delivery" ? (
                  <Tag color="#a29bfe">Out-For-Delivery</Tag>
                ) : data.state === "Returned" ? (
                  <Tag color="volcano">Returned</Tag>
                ) : data.state === "Canceled" ? (
                  <Tag color="red">Canceled</Tag>
                ) : null}

                <p>{this.makeLocalTime(data.updated_at)}</p>
              </>,
              
                <Row gutter={12}>
                <Col xs={12}>
                  <h3 style={{fontWeight: 'bold'}}>Recipient Info</h3>
                  <Row>
                    <Col xs={8}><p style={{margin: '2px 0px'}}>Name: </p></Col> <Col xs={16}>{data?.recipient_name}</Col>
                    <Col xs={8}><p style={{margin: '2px 0px'}}>Mobile Number: </p></Col> <Col xs={16}>{data?.recipient_mobile_number}</Col>
                    <Col xs={8}><p style={{margin: '2px 0px'}}>Address: </p></Col> <Col xs={16}>{data?.recipient_address}</Col>
                  </Row>
                </Col>
                
                <Col xs={12}>
                  <h3 style={{fontWeight: 'bold'}}>Charge Info</h3>
                  {/* <Row>
                    <Col xs={12}><p style={{margin: '2px 0px'}}>Actual price: </p></Col><Col xs={12}>{(data?.charges?.amount_to_collect)?.toPrecision(2)}</Col>
                    <Col xs={12}><p style={{margin: '2px 0px'}}>Delivery Charge: </p></Col><Col xs={12}>{data?.charges?.delivery_charge?.toPrecision(2)}</Col>
                    <Col xs={12}><p style={{margin: '2px 0px'}}>Payable Amount: </p></Col><Col xs={12}>{data?.charges?.payable_amount?.toPrecision(2)}</Col>
                    <Col xs={12}><p style={{margin: '2px 0px'}}>Return Charge: </p></Col><Col xs={12}>{data?.charges?.return_charge?.toPrecision(2)}</Col>
                    <Col xs={12}><p style={{margin: '2px 0px'}}>COD Charge: </p></Col><Col xs={12}>{data?.charges?.cod?.toPrecision(2)}</Col>
                  
                  </Row> */}
                  {data.type === "NO" ? 
                  <Row>
                  
                    <Col xs={12}><p style={{margin: '2px 0px'}}>Cash Collection  </p></Col><Col xs={12}>{Math.sign(data?.charges?.amount_to_collect) !== -1 ? <span> &nbsp; { data?.charges?.amount_to_collect}</span>  : <span style={{color: 'red'}}>{data?.charges?.amount_to_collect}</span>}</Col>
                    <Col xs={12}><p style={{margin: '2px 0px'}}>Delivery Charge: </p></Col><Col xs={12}>{Math.sign(data?.charges?.delivery_charge) !== -1 ? <span> &nbsp; {data?.charges?.delivery_charge}</span>  : <span style={{color: 'red'}}>{data?.charges?.delivery_charge}</span>}</Col>
                    <Col xs={12}><p style={{margin: '2px 0px'}}>Return Charge: </p></Col><Col xs={12}>{Math.sign(data?.charges?.return_charge) !== -1 ? <span> &nbsp; {data?.charges?.return_charge}</span> : <span style={{color: 'red'}}>{data?.charges?.return_charge}</span>}</Col>
                    <Col xs={12}><p style={{margin: '2px 0px'}}>COD Charge: </p></Col><Col xs={12}>{Math.sign(data?.charges?.cod) !== -1 ? <span> &nbsp; {data?.charges?.cod}</span> : <span style={{color: 'red'}}>{data?.charges?.cod?.toPrecision(2)}</span>}</Col>
                    <Col xs={24}>
                    <hr />
                    </Col>
                    <Col xs={12}><p style={{margin: '2px 0px', fontWeight: 'bold'}}>Payable Amount: </p></Col><Col xs={12} style={{fontWeight: 'bold'}}>{Math.sign(data?.charges?.payable_amount) !== -1 ? <span> &nbsp; {data?.charges?.payable_amount}</span> : <span style={{color: 'red'}}>{data?.charges?.payable_amount}</span>}</Col>
                  </Row>
                  :
                  <Row>
                    <Col xs={12}><p style={{margin: '2px 0px', fontWeight: 'bold'}}>Return Charge: </p></Col><Col xs={12} style={{fontWeight: 'bold'}}>{Math.sign(data?.charges?.return_charge) !== -1 ? <span> &nbsp; {data?.charges?.return_charge}</span> : <span style={{color: 'red'}}>{data?.charges?.return_charge}</span>}</Col>
                  </Row>
                  }
                </Col>
              </Row>
              ,

              <>
                {data.type === "NO" ? 
                  data.state === "Pending" ? 
                  <>
                  <Button style={{margin: '2px'}}
                  size="small"
                  type="primary"
                  icon={<EditOutlined />}
                  onClick={() =>
                    history.push(`/parcel-edit?store_id=${this.state?.shop_id}&&order_id=${data.order_id}&&mobile=${data?.recipient_mobile_number}`)
                  }
                >
                  Edit
                </Button>
                <Button style={{margin: '2px'}} size="small" type="primary" icon={<DownloadOutlined />} onClick = {() => {this.handlePrintData(data);this.printDocument()}} >Invoice</Button> &nbsp; 
                </>
                :null:null}
                &nbsp;
                <Button style={{margin: '2px'}}
                  size="small"
                  type="primary"
                  icon={<EyeFilled />}
                  onClick={() =>
                    window.open(
                      `/parcel-details?shop_id=${this.state?.shop_id}&order_id=${data?.order_id}&mobile_number=${data?.recipient_mobile_number}`,
                      "_blank"
                    )
                  }
                >
                  View
                </Button>
                <Button style={{margin: '2px'}} size="small" type="primary" icon={<DownloadOutlined />} onClick = {() => {this.handlePrintData(data);this.printDocument()}} >Invoice</Button> &nbsp; 
                {" "}
                &nbsp;
                {data.type === "NO" ? (
                  data.state === "Pending" ? (
                    <Popconfirm
                      title="Are you sure？"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => this.handleCancel(data?.order_id)}
                    >
                      <Button
                        size="small"
                        type="danger"
                        icon={<DeleteOutlined />}
                      >
                        Cancel Order
                      </Button>
                    </Popconfirm>
                  ) : null
                ) : null}
                {/*<br /> 
          <br />*/}
            
              </>
            )
          )
        : "",
    ];

    const shopForm = () => {
      return <Form
      name="basic"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      initialValues={{
        remember: true,
      }}
      onFinish={this.onFinish}
      onFinishFailed={this.onFinishFailed}
      autoComplete="off"
      requiredMark={false}
    >
      <Row gutter={16}>
        <Col xs={24} md={24}>
          <Form.Item
            label={
              lan == "bangla" ? "আপনার সপ সিলেক্ট করুন" : "Select Store"
            }
            name="shop"
            rules={[
              {
                required: true,
                message: "This field is required",
              },
            ]}
          >
            <Select
              showSearch
              style={{ width: "100%" }}
              onChange={(e) => this.handleShopSearch(e)}
            >
              {option.map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      </Form>
    }

    const resultInfo = () => (

      data ? (
        <Row gutter={10}>
          <Col xs={24}>
            <Button
              style={{}}
              type="primary"
              icon={<PlusSquareOutlined />}
              onClick={() => history.push(
                `/parcel-create?id=${this.state.shop_id}`
              )}
            >
              {lan == "bangla" ? `পার্সেল তৈরি করুন` : `Create a parcel`}
            </Button>
            <Button
            className="excel-button"
              style={{}}
              type="primary"
              icon={<PlusSquareOutlined />}
              onClick={() => history.push(
                `/bulk-input?id=${this.state.shop_id}`
              )}
            >
              {lan == "bangla" ? `এক্সেল ফাইল আপ্লোড করুণ` : `Create bulk parcel`}
            </Button>
           
          </Col>
        </Row>
      ) : null
    )

    const dataSection = () => (
      data !== null ? (
        <>
          <Row gutter={10}>
            <Col xs={24}></Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Input
                placeholder={
                  lan == "bangla" ? "মার্চেন্ট আইডি" : "Merchant ID"
                }
                allowClear
                style={{ width: "99%", margin: "10px 0px" }}
                onChange={(e) =>
                  this.setState({ merchant_id: e.target.value })
                }
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Input
                placeholder={lan == "bangla" ? "বাহন আইডি" : "Bahon ID"}
                allowClear
                style={{ width: "99%", margin: "10px 0px" }}
                onChange={(e) =>
                  this.setState({ bahon_id: e.target.value })
                }
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Input
                placeholder={
                  lan == "bangla"
                    ? "কাস্টমারের মোবাইল নাম্বার"
                    : "Customer Mobile Number"
                }
                allowClear
                style={{ width: "99%", margin: "10px 0px" }}
                onChange={(e) =>
                  this.setState({ receipent_mobile: e.target.value })
                }
              />
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <Select
                showSearch
                style={{ width: "99%", margin: "10px 0px" }}
                placeholder={
                  lan == "bangla"
                    ? "স্টেটাস সিলেক্ট করুন"
                    : "Select a status"
                }
                name="status"
                optionFilterProp="children"
                onChange={(e) => this.setState({ status: e })}
                filterOption={(input, option) =>
                  option.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="All" selected>
                  All
                </Option>
                <Option value="Pending">Pending</Option>
                <Option value="Processing">Processing</Option>
                <Option value="Hold">Hold</Option>
                <Option value="Delivered">Delivered</Option>
                <Option value="Returned">Returned</Option>
              </Select>
            </Col>
            <Col xs={12} sm={12} md={8} lg={8} xl={8}>
              <RangePicker
                onChange={this.handleDate}
                format="YYYY/MM/DD"
                style={{ width: "100%", margin: "10px 0px" }}
              />
            </Col>
            <br />

            <Col
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={3}
              style={{ margin: "10px 0px" }}
            >
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Tooltip title="Search" onClick={this.handleApiSearch}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<SearchOutlined />}
                />
              </Tooltip>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <Tooltip title="Reset" onClick={this.resetSearch}>
                <Button
                  type="primary"
                  shape="circle"
                  icon={<ClearOutlined />}
                />
              </Tooltip>
            </Col>
          </Row>
          <br />
          <Table
            columns={columns}
            scroll={{ x: 10 }}
            dataSource={rows[0]}
            pagination={false}
                rowkey={(record) => console.log(record.key)} 
                expandable={{
                  expandedRowRender: record => <p style={{ margin: 0 }}>{record.details}</p>,
                  expandRowByClick: true
                  
                }}
          />
          <br />
          <Pagination
            defaultCurrent={1}
            total={total}
            pageSize={50}
            style={{ textAlign: "right", paddingRight: "100px" }}
            onChange={(page) => this.handlePagination(page)}
          />
        </>
      ) : null
    )

    const noStore = () => (
      shops.length === 0 ?
        <FlexContainer style={{ height: "50vh" }}>
          <div
            style={{
              alignSelf: "center",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              width: "50%",
            }}
          >
            <h1
              style={{
                textAlign: "center",
                alignSelf: "center",
                fontWeight: "600",
              }}
            >
              {lan === "bangla"
                ? "নতুন স্টোর তৈরি করুন"
                : "Create Your new store"}
            </h1>
            <p style={{ textAlign: "center", color: "gray" }}>
              {lan === "bangla"
                ? "আপনার কোনো স্টোর এখনো নেই, পার্সেল এড কয়ার জন্য স্টোর তৈরি করুন"
                : "You don't have store. Add store to create parcel"}
            </p>
            <Button
              type="primary"
              style={{ textAlign: "center" }}
              onClick={this.onCreate}
            >
              {lan === "bangla" ? "এড স্টোর" : "Add Store"}
            </Button>
          </div>
        </FlexContainer>
        : null)

    const storeName = () => (
      localStorage.getItem('active_store_name')
      ?
        <>
          <Tag color="#2db7f5" style={{fontSize: '18px',padding: '5px', textAlign: 'center'}} >{lan === 'bangla'? `স্টোরের নাম - ${localStorage.getItem('active_store_name')}` : `Current Store - ${localStorage.getItem('active_store_name')}`}</Tag>
          <br />
        </>
      :null
    )
    
    const paymentSection = () => (
      this.state.isValid == false ? 
        <Tag onClick={() =>this.setState({createPaymentForm: true})} color="red" style={{width: '100%', padding: '10px', fontWeight: '550', textAlign: 'center', fontSize:'16px', cursor: 'pointer'}}>{lan === 'bangla' ? '***স্টোরের পেমেন্ট এর পদ্ধতি যুক্ত করা হয় নি, পেমেন্ট পদ্ধতি যুক্ত করন***' : '***This store has no payment method, please add payment method***'}</Tag>
      : null
    )
    const pageHeader = () => (

      <PageHeader
      ghost={false}
      onBack={() => window.history.back()}
      title="Parcel List"
      extra={
        <>
            {data ?
            <>
             
            <Button
              type="primary"
              icon={<PlusSquareOutlined />}
              size="middle"
              onClick={() => history.push(
                `/parcel-create?id=${this.state.shop_id}`
              )}
            >
              {lan == "bangla" ? `পার্সেল তৈরি করুন` : `Create a parcel`}
            </Button>
            <button
              type="primary"
              danger
              className="excel-button"
              icon={<PlusSquareOutlined />}
              size="middle"
              onClick={() => history.push(
                `/parcel-bulk-input?id=${this.state.shop_id}`
              )}
            >
              {lan == "bangla" ? `এক্সেল ফাইল আপ্লোড করুণ` : `Create bulk parcel`}
            </button>
            </>
            :null}
           {this.state.total !== 0 && this.state.total !==null ? (
             
              <Tag
                style={{
                  fontSize: "14px",
                  padding: "5px",
                  float: "right",
                }}
                color="#87d068"
              >
                {lan == "bangla"
                  ? `${this.state.total} টি ফলাফল পাওয়া গিয়েছে`
                  : `Total ${this.state.total} data found`}
              </Tag>
            ) : (
              <Tag
                style={{
                  fontSize: "14px",
                  padding: "5px",
                  float: "right",
                }}
                color="#ce821f"
              >
                {lan == "bangla"
                  ? `কোনো ফলাফল পাওয়া যায় নি`
                  : `No data found`}
              </Tag>
            )}
        </>
      }
    ></PageHeader>
    )
    return (
      <Container>
        {shops !== null ? (
          <>
            {pageHeader()}
            {shopForm()}
            {storeName()}
            {paymentSection()}
            {/* {resultInfo()} */}
            {dataSection()}
            {noStore()}
          </>
        ) : (
          null
        )}
        <CreateStore status={this.state.createForm} close={this.onClose} refresh = {this.handleRefresh} re={true} />
        <AddPaymentMethod shop_id={this.state.shop_id} refresh={this.handleShopRefresh} status={this.state.createPaymentForm} close = {this.onPaymentClose} />
     
       {this.state.visible ? 
        <>
          <PrintBarCode ref={(el) => (this.PrintBarCode = el)} clsfnc= {() => this.setState({visible: false})} />
          <span onClick={() => {this.setState({receipt_no:''})}}>
            <ReactToPrint
            trigger={() => <a href="#" style={{position: 'relative', top: '20px',backgroundColor: '#3498db', padding: '12px 170px', textDecoration: 'none', color: '#fff'}} >Print this out!</a>}
            content={() => this.PrintBarCode}
            />
          </span>
        </>
       :null}
        
      </Container>
    );
  }
}

export default connect(null, { fetchOrders, cancelOrder, fetchShops,fetchStore,notificationCount })(
  ParcelList
);
