
import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Statistic } from 'antd';
import { LoginContainer, FlexContainer } from './style';
import { Link } from 'react-router-dom';
import history from '../../history';
import { connect } from 'react-redux';
import {getOTP,forgetPass} from '../../action/loginAction';
import PasswordReset from './passwordReset';
const { Countdown } = Statistic;
const ForgotPasswordverification = ({getOTP,forgetPass}) => {
    const [verified, setVerified] = useState(false)
    const [resend, setResend] = useState(false)
    const [mobile, setMobile] = useState(null)
    const [lan, setLan] = useState(false)
    const onFinish = async (values) => {
        setMobile(values.mobile)
        const res =  await getOTP(values.mobile)
        if(res === 201){
            setVerified(true)
        }
        setVerified(res)
      };
    
      const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
      };

      useEffect(() => {
        
        setLan(localStorage.getItem('lan') ? localStorage.getItem('lan') : 'english')
        window.scrollTo(0, 0);
      }, [])
      
      
const deadline = Date.now() + 1000 * 120;
// console.log(resend)
    return ( 
        <FlexContainer>
            <LoginContainer>
                <img src='./images/amvines-logo.png' />
                <h2 style={{margin: '30px 0px', alignSelf: 'center'}}>{lan === 'bangla' ? "পাসওয়ার্ড রিকভার করুন" : "Reset Your Password"}</h2>
                
                {!verified  ? 
                    <PasswordReset mobile_number={mobile} />
                :
                    <>
                        <Form
                        name="basic"
                        labelCol={{
                            span: 24,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        style={{width: '100%'}}
                        requiredMark={false}
                        >
                        <Form.Item
                            label={lan === 'bangla' ? "আপনার রেজিস্ট্রার্ড মোবাইল নম্বরটি দিন" : "Enter your registered phone number"}
                            name="mobile"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your mobile number!',
                            },
                            ]}
                        >
                            <Input />
                        </Form.Item>
        {/* 
                        <Form.Item
                            label="Confirm Password"
                            name="confirm_password"
                            rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                            ]}
                        >
                            <Input.Password />
                        </Form.Item> */}


                        <Form.Item
                            style={{textAlign: 'center'}}
                        >
                            <Button type="primary" htmlType="submit">
                            {lan === 'bangla' ? "সাবমিট করুন" : "Submit"}
                            </Button>
                        </Form.Item>
                        <p><Link to="/login">
                            {lan === 'bangla' ? "লগইন পেজে ফিরে যান" : "Back to Login"}</Link></p>
                        </Form>
                    </>
                }
            </LoginContainer>
        </FlexContainer>
     );
}
 
export default connect(null,{getOTP,forgetPass})(ForgotPasswordverification);